<template>
  <div class="kpi-machines-wrapper">
    <div class="kpi-machines-table">
      <div class="aligner">
        <machines-table />
      </div>
    </div>
  </div>
</template>

<script>
import MachinesTable from './MachinesTable.vue'

export default {
  name: 'MachinesKpi',
  components: {
    MachinesTable
  }
}
</script>

<style lang="scss" scoped>
div.kpi-machines-wrapper {
  width: 100%;

  div.kpi-machines-table {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.aligner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1108px;
    }
  }
}

@media screen and (max-width: $mobile) {
  div.kpi-machines-wrapper {
    div.kpi-machines-table {
      padding: 0;
    }
  }
}
</style>
