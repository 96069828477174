<template>
  <div class="machines-table">
    <loading-spinner v-if="isLoading" />
    <v-data-table
      v-else
      :headers="headers"
      :items="tableData"
      hide-default-footer
      mobile-breakpoint="960"
      class="table-wrapper"
    >
      <!-- eslint-disable -->
      <template v-slot:header.kw="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.tasks="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <!-- <template v-slot:header.reportedTasks="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template> -->
      <template v-slot:header.finishedTasks="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.pausedTasks="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.unclaimedTasks="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.unfinishedTasks="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.totalRepairTime="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.totalDowntime="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>

      <template v-slot:item.kw="{ item }">
        {{ item.kw }}
      </template>
      <template v-slot:item.tasks="{ item }">
        {{ item.tasks }}
      </template>
      <!-- <template v-slot:item.reportedTasks="{ item }">
        {{ item.reportedTasks }}
      </template> -->
      <template v-slot:item.finishedTasks="{ item }">
        {{ item.finishedTasks }}
      </template>
      <template v-slot:item.pausedTasks="{ item }">
        {{ item.pausedTasks }}
      </template>
      <template v-slot:item.unclaimedTasks="{ item }">
        {{ item.unclaimedTasks }}
      </template>
      <template v-slot:item.unfinishedTasks="{ item }">
        {{ item.unfinishedTasks }}
      </template>
      <template v-slot:item.totalRepairTime="{ item }">
        {{ item.totalRepairTime }}
      </template>
      <template v-slot:item.totalDowntime="{ item }">
        {{ item.totalDowntime }}
      </template>

      <template slot="body.append">
        <tr class="summary">
          <th>
            {{ $t('kpi.tables.machines.sum') }}:
          </th>
          <th>{{ summary.tasks }}</th>
          <th>{{ summary.finishedTasks }}</th>
          <th>{{ summary.pausedTasks }}</th>
          <th>{{ summary.unclaimedTasks }}</th>
          <th>{{ summary.unfinishedTasks }}</th>
          <th>{{ summary.totalRepairTime }}</th>
          <th>{{ summary.totalDowntime }}</th>
        </tr>
      </template>
      <!-- eslint-enable -->
    </v-data-table>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/GlobalComponents/LoadingSpinner'
import { mapGetters } from 'vuex'
import getWeekNumber from '@/helpers/getWeekNumber'

export default {
  name: 'MachinesTable',
  components: {
    LoadingSpinner
  },
  data () {
    return {
      isLoading: false,
      interval: null,
      headers: [
        { text: this.$t('kpi.tables.machines.headers.kw'), value: 'kw' },
        { text: this.$t('kpi.tables.machines.headers.tasks'), value: 'tasks' },
        // { text: this.$t('kpi.tables.machines.headers.reportedTasks'), value: 'reportedTasks' },
        { text: this.$t('kpi.tables.machines.headers.finishedTasks'), value: 'finishedTasks' },
        { text: this.$t('kpi.tables.machines.headers.pausedTasks'), value: 'pausedTasks' },
        { text: this.$t('kpi.tables.machines.headers.unclaimedTasks'), value: 'unclaimedTasks' },
        { text: this.$t('kpi.tables.machines.headers.unfinishedTasks'), value: 'unfinishedTasks' },
        { text: this.$t('kpi.tables.machines.headers.totalRepairTime'), value: 'totalRepairTime' },
        { text: this.$t('kpi.tables.machines.headers.totalDowntime'), value: 'totalDowntime' }
      ],
      tableData: [],
      summary: {}
    }
  },
  computed: {
    ...mapGetters({
      getWeek: 'KpiDateRangeFilter/getWeek',
      getUserData: 'User/getUserData'
    })
  },
  watch: {
    getWeek (newVal, oldVal) {
      this.getMachinesTableData()
    }
  },
  created () {
    this.getMachinesTableData()
    this.interval = setInterval(async () => {
      await Promise.allSettled([this.getMachinesTableData()])
    }, ['ADMIN', 'HEAD_MAINTAINER'].includes(this.getUserData.role) ? 60000 : 120000)
  },
  beforeUnmount () {
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    getWeekNumber,
    async getMachinesTableData () {
      try {
        this.isLoading = true
        const { data } = await this.$axios.get('/machine-errors/?select=*,tasks(*,user,activePause)')

        // convert time
        const convertedDataTime = data.map(a => ({
          createdAt: new Date(new Date(a.createdAt).setHours(0, 0, 0, 0)),
          // startedAt: new Date(new Date(a.startedAt).setHours(0, 0, 0, 0))
          startedAt: a.startedAt,
          // finishedAt: new Date(new Date(a.finishedAt).setHours(0, 0, 0, 0)),
          finishedAt: a.finishedAt === null ? a.finishedAt : new Date(new Date(a.finishedAt).setHours(0, 0, 0, 0)),
          mainTask: a.mainTask,
          duration: (((new Date(a.finishedAt) - new Date(a.createdAt)) / 1000) / 60) / 60,
          durationInMinutes: ((new Date(a.finishedAt).getTime() - new Date(a.createdAt).getTime()) / 1000) / 60,
          tasks: a.tasks
        }))

        // STARA VERZIA, rolling tyzden (aktualny den - 6 dni)
        // get dates - bez ohladu na cas
        // const todayDate = new Date()
        // const today0 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate())
        // const today6 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 6)

        // const today7 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 7)
        // const today13 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 13)

        // const today14 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 14)
        // const today21 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 21)

        // const today22 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 22)
        // const today29 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 29)

        // const today30 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 30)
        // const today37 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 37)

        // NOVA VERZIA, tyzden od pondelka do nedele, triedenie podla cisla tyzdna
        const thisWeekNumber = getWeekNumber(new Date())

        // 1.stlpec: Pocet poruch - poruchy ktore boli nahlasene
        // rozdelenie taskov na tyzdne podla createdAt
        const tasksWeek1 = []
        const tasksWeek2 = []
        const tasksWeek3 = []
        const tasksWeek4 = []
        const tasksWeek5 = []
        // for (let i = 0; i < convertedDataTime.length; i++) {
        //   if (convertedDataTime[i].startedAt !== null) {
        //     if (convertedDataTime[i].createdAt <= today0 && convertedDataTime[i].createdAt >= today6) {
        //       tasksWeek1.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today7 && convertedDataTime[i].createdAt >= today13) {
        //       tasksWeek2.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today14 && convertedDataTime[i].createdAt >= today21) {
        //       tasksWeek3.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today22 && convertedDataTime[i].createdAt >= today29) {
        //       tasksWeek4.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today30 && convertedDataTime[i].createdAt >= today37) {
        //       tasksWeek5.push(convertedDataTime[i])
        //     }
        //   }
        // }

        for (let i = 0; i < convertedDataTime.length; i++) {
          if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber) {
            tasksWeek1.push(convertedDataTime[i])
          } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 1) {
            tasksWeek2.push(convertedDataTime[i])
          } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 2) {
            tasksWeek3.push(convertedDataTime[i])
          } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 3) {
            tasksWeek4.push(convertedDataTime[i])
          } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 4) {
            tasksWeek5.push(convertedDataTime[i])
          }
        }

        // 2.stlpec: Pocet nahlasenych poruch - poruchy ktore nie su pridelene (zaradenie podla createdAt, vypocet podla mainTask === null)
        // rozdelenie taskov na tyzdne podla createdAt
        // const reportedTasksWeek1 = []
        // const reportedTasksWeek2 = []
        // const reportedTasksWeek3 = []
        // const reportedTasksWeek4 = []
        // const reportedTasksWeek5 = []
        // for (let i = 0; i < convertedDataTime.length; i++) {
        //   if (convertedDataTime[i].mainTask === null) {
        //     if (convertedDataTime[i].createdAt <= today0 && convertedDataTime[i].createdAt >= today6) {
        //       reportedTasksWeek1.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today7 && convertedDataTime[i].createdAt >= today13) {
        //       reportedTasksWeek2.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today14 && convertedDataTime[i].createdAt >= today21) {
        //       reportedTasksWeek3.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today22 && convertedDataTime[i].createdAt >= today29) {
        //       reportedTasksWeek4.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today30 && convertedDataTime[i].createdAt >= today37) {
        //       reportedTasksWeek5.push(convertedDataTime[i])
        //     }
        //   }
        // }
        // for (let i = 0; i < convertedDataTime.length; i++) {
        //   if (convertedDataTime[i].mainTask === null) {
        //     if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber) {
        //       reportedTasksWeek1.push(convertedDataTime[i])
        //     } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 1) {
        //       reportedTasksWeek2.push(convertedDataTime[i])
        //     } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 2) {
        //       reportedTasksWeek3.push(convertedDataTime[i])
        //     } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 3) {
        //       reportedTasksWeek4.push(convertedDataTime[i])
        //     } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 4) {
        //       reportedTasksWeek5.push(convertedDataTime[i])
        //     }
        //   }
        // }

        // 3.stlpec: Pocet ukoncenych poruch - poruchy ktore su ukoncene (podla finishedAt)
        const finishedTasksWeek1 = []
        const finishedTasksWeek2 = []
        const finishedTasksWeek3 = []
        const finishedTasksWeek4 = []
        const finishedTasksWeek5 = []
        // for (let i = 0; i < convertedDataTime.length; i++) {
        //   if (convertedDataTime[i].finishedAt !== null) {
        //     if (convertedDataTime[i].finishedAt <= today0 && convertedDataTime[i].finishedAt >= today6) {
        //       finishedTasksWeek1.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].finishedAt <= today7 && convertedDataTime[i].finishedAt >= today13) {
        //       finishedTasksWeek2.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].finishedAt <= today14 && convertedDataTime[i].finishedAt >= today21) {
        //       finishedTasksWeek3.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].finishedAt <= today22 && convertedDataTime[i].finishedAt >= today29) {
        //       finishedTasksWeek4.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].finishedAt <= today30 && convertedDataTime[i].finishedAt >= today37) {
        //       finishedTasksWeek5.push(convertedDataTime[i])
        //     }
        //   }
        // }
        for (let i = 0; i < convertedDataTime.length; i++) {
          if (convertedDataTime[i].finishedAt !== null) {
            if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber) {
              finishedTasksWeek1.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber - 1) {
              finishedTasksWeek2.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber - 2) {
              finishedTasksWeek3.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber - 3) {
              finishedTasksWeek4.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber - 4) {
              finishedTasksWeek5.push(convertedDataTime[i])
            }
          }
        }

        // 4.stlpec: Pocet pozastavenych poruch - poruchy ktore su pozastavene (podla mainTask.activePause.startAt)
        const pausedTasksWeek1 = []
        const pausedTasksWeek2 = []
        const pausedTasksWeek3 = []
        const pausedTasksWeek4 = []
        const pausedTasksWeek5 = []
        // for (let i = 0; i < convertedDataTime.length; i++) {
        //   if (convertedDataTime[i].mainTask && convertedDataTime[i].mainTask.activePause !== null) {
        //     if (new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) <= today0 && new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) >= today6) {
        //       pausedTasksWeek1.push(convertedDataTime[i])
        //     } else if (new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) <= today7 && new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) >= today13) {
        //       pausedTasksWeek2.push(convertedDataTime[i])
        //     } else if (new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) <= today14 && new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) >= today21) {
        //       pausedTasksWeek3.push(convertedDataTime[i])
        //     } else if (new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) <= today22 && new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) >= today29) {
        //       pausedTasksWeek4.push(convertedDataTime[i])
        //     } else if (new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) <= today30 && new Date(new Date(convertedDataTime[i].mainTask.activePause.startAt).setHours(0, 0, 0, 0)) >= today37) {
        //       pausedTasksWeek5.push(convertedDataTime[i])
        //     }
        //   }
        // }
        for (let i = 0; i < convertedDataTime.length; i++) {
          if (convertedDataTime[i].mainTask && convertedDataTime[i].mainTask.activePause !== null) {
            if (getWeekNumber(new Date(new Date(convertedDataTime[i].mainTask.activePause.startedAt).setHours(0, 0, 0, 0))) === thisWeekNumber) {
              pausedTasksWeek1.push(convertedDataTime[i])
            } else if (getWeekNumber(new Date(new Date(convertedDataTime[i].mainTask.activePause.startedAt).setHours(0, 0, 0, 0))) === thisWeekNumber - 1) {
              pausedTasksWeek2.push(convertedDataTime[i])
            } else if (getWeekNumber(new Date(new Date(convertedDataTime[i].mainTask.activePause.startedAt).setHours(0, 0, 0, 0))) === thisWeekNumber - 2) {
              pausedTasksWeek3.push(convertedDataTime[i])
            } else if (getWeekNumber(new Date(new Date(convertedDataTime[i].mainTask.activePause.startedAt).setHours(0, 0, 0, 0))) === thisWeekNumber - 3) {
              pausedTasksWeek4.push(convertedDataTime[i])
            } else if (getWeekNumber(new Date(new Date(convertedDataTime[i].mainTask.activePause.startedAt).setHours(0, 0, 0, 0))) === thisWeekNumber - 4) {
              pausedTasksWeek5.push(convertedDataTime[i])
            }
          }
        }

        // 5.stlpec: Pocet neprevzatych poruch - poruchy ktore su prevzate ale este user nezacal pracovat (triedenie podla createdAt, vypocet podla startedAt)
        const unclaimedTasksWeek1 = []
        const unclaimedTasksWeek2 = []
        const unclaimedTasksWeek3 = []
        const unclaimedTasksWeek4 = []
        const unclaimedTasksWeek5 = []
        // for (let i = 0; i < convertedDataTime.length; i++) {
        //   if (convertedDataTime[i].mainTask && convertedDataTime[i].startedAt === null) {
        //     if (convertedDataTime[i].createdAt <= today0 && convertedDataTime[i].createdAt >= today6) {
        //       unclaimedTasksWeek1.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today7 && convertedDataTime[i].createdAt >= today13) {
        //       unclaimedTasksWeek2.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today14 && convertedDataTime[i].createdAt >= today21) {
        //       unclaimedTasksWeek3.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today22 && convertedDataTime[i].createdAt >= today29) {
        //       unclaimedTasksWeek4.push(convertedDataTime[i])
        //     } else if (convertedDataTime[i].createdAt <= today30 && convertedDataTime[i].createdAt >= today37) {
        //       unclaimedTasksWeek5.push(convertedDataTime[i])
        //     }
        //   }
        // }
        for (let i = 0; i < convertedDataTime.length; i++) {
          if (convertedDataTime[i].mainTask && convertedDataTime[i].startedAt === null) {
            if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber) {
              unclaimedTasksWeek1.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 1) {
              unclaimedTasksWeek2.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 2) {
              unclaimedTasksWeek3.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 3) {
              unclaimedTasksWeek4.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 4) {
              unclaimedTasksWeek5.push(convertedDataTime[i])
            }
          }
        }

        // 6. stlpec - Pocet rozpracovanych poruch - poruchy ktore su zacate ale neukoncene (triedenie podla createdAt)
        const unfinishedTasksWeek1 = []
        const unfinishedTasksWeek2 = []
        const unfinishedTasksWeek3 = []
        const unfinishedTasksWeek4 = []
        const unfinishedTasksWeek5 = []

        for (let i = 0; i < convertedDataTime.length; i++) {
          if (convertedDataTime[i].startedAt !== null && convertedDataTime[i].finishedAt === null) {
            if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber) {
              unfinishedTasksWeek1.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 1) {
              unfinishedTasksWeek2.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 2) {
              unfinishedTasksWeek3.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 3) {
              unfinishedTasksWeek4.push(convertedDataTime[i])
            } else if (getWeekNumber(convertedDataTime[i].createdAt) === thisWeekNumber - 4) {
              unfinishedTasksWeek5.push(convertedDataTime[i])
            }
          }
        }

        // 7.stlpec: Celkova cas opravy strojov - poruchy ktore su ukoncene (finishedAt - createdAt) - vsetky pauzy, triedenie podla finishedAt
        const totalRepairTimeData = []
        for (let i = 0; i < convertedDataTime.length; i++) {
          if (convertedDataTime[i].startedAt !== null) {
            totalRepairTimeData.push(convertedDataTime[i])
          }
        }

        // vypocet trvani vsetkych pauz
        const totalRepairTimeDataDuration = totalRepairTimeData.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          durationInMinutes: a.durationInMinutes,
          tasks: a.tasks.map(b => ({
            pauses: b.pauses.map(c => ({
              duration: (((new Date(c.finishAt).getTime() - new Date(c.startAt).getTime()) / 1000) / 60)
            }))
          }))
        }))

        // zratanie pauz v kazdom tasku
        const totalRepairTimeDataPauseTime = totalRepairTimeDataDuration.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          durationInMinutes: a.durationInMinutes,
          tasks: a.tasks.map(b => ({
            taskPauseTotalTime: b.pauses.map(c => c.duration).reduce((result, item) => result + item, 0)
          }))
        }))

        // scitanie pauz vsetkych taskov
        const totalRepairTimeDataAllTasksPauseTime = totalRepairTimeDataPauseTime.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          durationInMinutes: a.durationInMinutes,
          totalPauseTime: a.tasks.map(b => b.taskPauseTotalTime).reduce((result, item) => result + item, 0)
        }))

        // vypocitanie skutocneho casu trvania tasku (celkove trvanie - trvanie vsetkych pauz dokopy)
        const totalRepairTimeDataFinalTimes = totalRepairTimeDataAllTasksPauseTime.map(a => ({
          createdAt: a.createdAt,
          finishedAt: a.finishedAt,
          durationInMinutes: a.durationInMinutes,
          totalPauseTime: a.totalPauseTime,
          taskTime: a.durationInMinutes - a.totalPauseTime
        }))

        const totalRepairTimeWeek1 = []
        const totalRepairTimeWeek2 = []
        const totalRepairTimeWeek3 = []
        const totalRepairTimeWeek4 = []
        const totalRepairTimeWeek5 = []

        // for (let i = 0; i < totalRepairTimeDataFinalTimes.length; i++) {
        //   if (totalRepairTimeDataFinalTimes[i].finishedAt <= today0 && totalRepairTimeDataFinalTimes[i].finishedAt >= today6) {
        //     totalRepairTimeWeek1.push(totalRepairTimeDataFinalTimes[i].taskTime)
        //   } else if (totalRepairTimeDataFinalTimes[i].finishedAt <= today7 && totalRepairTimeDataFinalTimes[i].finishedAt >= today13) {
        //     totalRepairTimeWeek2.push(totalRepairTimeDataFinalTimes[i].taskTime)
        //   } else if (totalRepairTimeDataFinalTimes[i].finishedAt <= today14 && totalRepairTimeDataFinalTimes[i].finishedAt >= today21) {
        //     totalRepairTimeWeek3.push(totalRepairTimeDataFinalTimes[i].taskTime)
        //   } else if (totalRepairTimeDataFinalTimes[i].finishedAt <= today22 && totalRepairTimeDataFinalTimes[i].finishedAt >= today29) {
        //     totalRepairTimeWeek4.push(totalRepairTimeDataFinalTimes[i].taskTime)
        //   } else if (totalRepairTimeDataFinalTimes[i].finishedAt <= today30 && totalRepairTimeDataFinalTimes[i].finishedAt >= today37) {
        //     totalRepairTimeWeek5.push(totalRepairTimeDataFinalTimes[i].taskTime)
        //   }
        // }

        for (let i = 0; i < totalRepairTimeDataFinalTimes.length; i++) {
          if (totalRepairTimeDataFinalTimes[i].finishedAt !== null) {
            if (getWeekNumber(totalRepairTimeDataFinalTimes[i].finishedAt) === thisWeekNumber) {
              totalRepairTimeWeek1.push(totalRepairTimeDataFinalTimes[i].taskTime)
            } else if (getWeekNumber(totalRepairTimeDataFinalTimes[i].finishedAt) === thisWeekNumber - 1) {
              totalRepairTimeWeek2.push(totalRepairTimeDataFinalTimes[i].taskTime)
            } else if (getWeekNumber(totalRepairTimeDataFinalTimes[i].finishedAt) === thisWeekNumber - 2) {
              totalRepairTimeWeek3.push(totalRepairTimeDataFinalTimes[i].taskTime)
            } else if (getWeekNumber(totalRepairTimeDataFinalTimes[i].finishedAt) === thisWeekNumber - 3) {
              totalRepairTimeWeek4.push(totalRepairTimeDataFinalTimes[i].taskTime)
            } else if (getWeekNumber(totalRepairTimeDataFinalTimes[i].finishedAt) === thisWeekNumber - 4) {
              totalRepairTimeWeek5.push(totalRepairTimeDataFinalTimes[i].taskTime)
            }
          }
        }

        let totalRepairTimeWeek1time = Math.round(totalRepairTimeWeek1.reduce((a, b) => a + b, 0) / totalRepairTimeWeek1.length)
        let totalRepairTimeWeek2time = Math.round(totalRepairTimeWeek2.reduce((a, b) => a + b, 0) / totalRepairTimeWeek2.length)
        let totalRepairTimeWeek3time = Math.round(totalRepairTimeWeek3.reduce((a, b) => a + b, 0) / totalRepairTimeWeek3.length)
        let totalRepairTimeWeek4time = Math.round(totalRepairTimeWeek4.reduce((a, b) => a + b, 0) / totalRepairTimeWeek4.length)
        let totalRepairTimeWeek5time = Math.round(totalRepairTimeWeek5.reduce((a, b) => a + b, 0) / totalRepairTimeWeek5.length)

        // osetrenie proti NaN, ak nie su za dany tyzden ziadne tasky
        if (isNaN(totalRepairTimeWeek1time)) {
          totalRepairTimeWeek1time = 0
        }
        if (isNaN(totalRepairTimeWeek2time)) {
          totalRepairTimeWeek2time = 0
        }
        if (isNaN(totalRepairTimeWeek3time)) {
          totalRepairTimeWeek3time = 0
        }
        if (isNaN(totalRepairTimeWeek4time)) {
          totalRepairTimeWeek4time = 0
        }
        if (isNaN(totalRepairTimeWeek5time)) {
          totalRepairTimeWeek5time = 0
        }

        // 8.stlpec: Celkova dlzka odstavky strojov - poruchy ktore su ukoncene (finishedAt - createdAt) - rataju sa len ukoncene poruchy od createdAt po finishedAt, triedenie podla finishedAt
        // vypocet celkovej dlzky odstavky strojov za kazdy tyzden so zaokruhlenim na 1 desatinne miesto
        const totalDowntimeWeek1 = []
        const totalDowntimeWeek2 = []
        const totalDowntimeWeek3 = []
        const totalDowntimeWeek4 = []
        const totalDowntimeWeek5 = []

        // for (let i = 0; i < convertedDataTime.length; i++) {
        //   if (convertedDataTime[i].finishedAt) {
        //     if (convertedDataTime[i].finishedAt <= today0 && convertedDataTime[i].finishedAt >= today6) {
        //       totalDowntimeWeek1.push(convertedDataTime[i].duration)
        //     } else if (convertedDataTime[i].finishedAt <= today7 && convertedDataTime[i].finishedAt >= today13) {
        //       totalDowntimeWeek2.push(convertedDataTime[i].duration)
        //     } else if (convertedDataTime[i].finishedAt <= today14 && convertedDataTime[i].finishedAt >= today21) {
        //       totalDowntimeWeek3.push(convertedDataTime[i].duration)
        //     } else if (convertedDataTime[i].finishedAt <= today22 && convertedDataTime[i].finishedAt >= today29) {
        //       totalDowntimeWeek4.push(convertedDataTime[i].duration)
        //     } else if (convertedDataTime[i].finishedAt <= today30 && convertedDataTime[i].finishedAt >= today37) {
        //       totalDowntimeWeek5.push(convertedDataTime[i].duration)
        //     }
        //   }
        // }

        for (let i = 0; i < convertedDataTime.length; i++) {
          if (convertedDataTime[i].finishedAt !== null) {
            if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber) {
              totalDowntimeWeek1.push(convertedDataTime[i].duration)
            } else if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber - 1) {
              totalDowntimeWeek2.push(convertedDataTime[i].duration)
            } else if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber - 2) {
              totalDowntimeWeek3.push(convertedDataTime[i].duration)
            } else if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber - 3) {
              totalDowntimeWeek4.push(convertedDataTime[i].duration)
            } else if (getWeekNumber(convertedDataTime[i].finishedAt) === thisWeekNumber - 4) {
              totalDowntimeWeek5.push(convertedDataTime[i].duration)
            }
          }
        }

        // scitanie vsetky casov v danom tyzdni, zaokruhlene na cele cislo
        const totalDowntimeWeek1time = Math.round(totalDowntimeWeek1.reduce((a, b) => a + b, 0) * 10) / 10
        const totalDowntimeWeek2time = Math.round(totalDowntimeWeek2.reduce((a, b) => a + b, 0) * 10) / 10
        const totalDowntimeWeek3time = Math.round(totalDowntimeWeek3.reduce((a, b) => a + b, 0) * 10) / 10
        const totalDowntimeWeek4time = Math.round(totalDowntimeWeek4.reduce((a, b) => a + b, 0) * 10) / 10
        const totalDowntimeWeek5time = Math.round(totalDowntimeWeek5.reduce((a, b) => a + b, 0) * 10) / 10

        // priradenie dat podla filtru
        if (this.getWeek === 1) {
          this.tableData.length = 0
          this.tableData.push(
            {
              kw: 1,
              tasks: tasksWeek1.length,
              // reportedTasks: reportedTasksWeek1.length,
              finishedTasks: finishedTasksWeek1.length,
              pausedTasks: pausedTasksWeek1.length,
              unclaimedTasks: unclaimedTasksWeek1.length,
              unfinishedTasks: unfinishedTasksWeek1.length,
              totalRepairTime: totalRepairTimeWeek1time,
              totalDowntime: totalDowntimeWeek1time
            }
          )
          this.summary = {
            tasks: tasksWeek1.length,
            finishedTasks: finishedTasksWeek1.length,
            pausedTasks: pausedTasksWeek1.length,
            unclaimedTasks: unclaimedTasksWeek1.length,
            unfinishedTasks: unfinishedTasksWeek1.length,
            totalRepairTime: totalRepairTimeWeek1time,
            totalDowntime: totalDowntimeWeek1time
          }
        } else if (this.getWeek === 2) {
          this.tableData.length = 0
          this.tableData.push(
            {
              kw: 1,
              tasks: tasksWeek1.length,
              // reportedTasks: reportedTasksWeek1.length,
              finishedTasks: finishedTasksWeek1.length,
              pausedTasks: pausedTasksWeek1.length,
              unclaimedTasks: unclaimedTasksWeek1.length,
              unfinishedTasks: unfinishedTasksWeek1.length,
              totalRepairTime: totalRepairTimeWeek1time,
              totalDowntime: totalDowntimeWeek1time
            },
            {
              kw: 2,
              tasks: tasksWeek2.length,
              // reportedTasks: reportedTasksWeek2.length,
              finishedTasks: finishedTasksWeek2.length,
              pausedTasks: pausedTasksWeek2.length,
              unclaimedTasks: unclaimedTasksWeek2.length,
              unfinishedTasks: unfinishedTasksWeek2.length,
              totalRepairTime: totalRepairTimeWeek2time,
              totalDowntime: totalDowntimeWeek2time
            }
          )
          this.summary = {
            tasks: tasksWeek1.length + tasksWeek2.length,
            finishedTasks: finishedTasksWeek1.length + finishedTasksWeek2.length,
            pausedTasks: pausedTasksWeek1.length + pausedTasksWeek2.length,
            unclaimedTasks: unclaimedTasksWeek1.length + unclaimedTasksWeek2.length,
            unfinishedTasks: unfinishedTasksWeek1.length + unfinishedTasksWeek2.length,
            totalRepairTime: totalRepairTimeWeek1time + totalRepairTimeWeek2time,
            totalDowntime: totalDowntimeWeek1time + totalDowntimeWeek2time
          }
        } else if (this.getWeek === 3) {
          this.tableData.length = 0
          this.tableData.push(
            {
              kw: 1,
              tasks: tasksWeek1.length,
              // reportedTasks: reportedTasksWeek1.length,
              finishedTasks: finishedTasksWeek1.length,
              pausedTasks: pausedTasksWeek1.length,
              unclaimedTasks: unclaimedTasksWeek1.length,
              unfinishedTasks: unfinishedTasksWeek1.length,
              totalRepairTime: totalRepairTimeWeek1time,
              totalDowntime: totalDowntimeWeek1time
            },
            {
              kw: 2,
              tasks: tasksWeek2.length,
              // reportedTasks: reportedTasksWeek2.length,
              finishedTasks: finishedTasksWeek2.length,
              pausedTasks: pausedTasksWeek2.length,
              unclaimedTasks: unclaimedTasksWeek2.length,
              unfinishedTasks: unfinishedTasksWeek2.length,
              totalRepairTime: totalRepairTimeWeek2time,
              totalDowntime: totalDowntimeWeek2time
            },
            {
              kw: 3,
              tasks: tasksWeek3.length,
              // reportedTasks: reportedTasksWeek3.length,
              finishedTasks: finishedTasksWeek3.length,
              pausedTasks: pausedTasksWeek3.length,
              unclaimedTasks: unclaimedTasksWeek3.length,
              unfinishedTasks: unfinishedTasksWeek3.length,
              totalRepairTime: totalRepairTimeWeek3time,
              totalDowntime: totalDowntimeWeek3time
            }
          )
          this.summary = {
            tasks: tasksWeek1.length + tasksWeek2.length + tasksWeek3.length,
            finishedTasks: finishedTasksWeek1.length + finishedTasksWeek2.length + finishedTasksWeek3.length,
            pausedTasks: pausedTasksWeek1.length + pausedTasksWeek2.length + pausedTasksWeek3.length,
            unclaimedTasks: unclaimedTasksWeek1.length + unclaimedTasksWeek2.length + unclaimedTasksWeek3.length,
            unfinishedTasks: unfinishedTasksWeek1.length + unfinishedTasksWeek2.length + unfinishedTasksWeek3.length,
            totalRepairTime: totalRepairTimeWeek1time + totalRepairTimeWeek2time + totalRepairTimeWeek3time,
            totalDowntime: totalDowntimeWeek1time + totalDowntimeWeek2time + totalDowntimeWeek3time
          }
        } else if (this.getWeek === 4) {
          this.tableData.length = 0
          this.tableData.push(
            {
              kw: 1,
              tasks: tasksWeek1.length,
              // reportedTasks: reportedTasksWeek1.length,
              finishedTasks: finishedTasksWeek1.length,
              pausedTasks: pausedTasksWeek1.length,
              unclaimedTasks: unclaimedTasksWeek1.length,
              unfinishedTasks: unfinishedTasksWeek1.length,
              totalRepairTime: totalRepairTimeWeek1time,
              totalDowntime: totalDowntimeWeek1time
            },
            {
              kw: 2,
              tasks: tasksWeek2.length,
              // reportedTasks: reportedTasksWeek2.length,
              finishedTasks: finishedTasksWeek2.length,
              pausedTasks: pausedTasksWeek2.length,
              unclaimedTasks: unclaimedTasksWeek2.length,
              unfinishedTasks: unfinishedTasksWeek2.length,
              totalRepairTime: totalRepairTimeWeek2time,
              totalDowntime: totalDowntimeWeek2time
            },
            {
              kw: 3,
              tasks: tasksWeek3.length,
              // reportedTasks: reportedTasksWeek3.length,
              finishedTasks: finishedTasksWeek3.length,
              pausedTasks: pausedTasksWeek3.length,
              unclaimedTasks: unclaimedTasksWeek3.length,
              unfinishedTasks: unfinishedTasksWeek3.length,
              totalRepairTime: totalRepairTimeWeek3time,
              totalDowntime: totalDowntimeWeek3time
            },
            {
              kw: 4,
              tasks: tasksWeek4.length,
              // reportedTasks: reportedTasksWeek4.length,
              finishedTasks: finishedTasksWeek4.length,
              pausedTasks: pausedTasksWeek4.length,
              unclaimedTasks: unclaimedTasksWeek4.length,
              unfinishedTasks: unfinishedTasksWeek4.length,
              totalRepairTime: totalRepairTimeWeek4time,
              totalDowntime: totalDowntimeWeek4time
            }
          )
          this.summary = {
            tasks: tasksWeek1.length + tasksWeek2.length + tasksWeek3.length + tasksWeek4.length,
            finishedTasks: finishedTasksWeek1.length + finishedTasksWeek2.length + finishedTasksWeek3.length + finishedTasksWeek4.length,
            pausedTasks: pausedTasksWeek1.length + pausedTasksWeek2.length + pausedTasksWeek3.length + pausedTasksWeek4.length,
            unclaimedTasks: unclaimedTasksWeek1.length + unclaimedTasksWeek2.length + unclaimedTasksWeek3.length + unclaimedTasksWeek4.length,
            unfinishedTasks: unfinishedTasksWeek1.length + unfinishedTasksWeek2.length + unfinishedTasksWeek3.length + unfinishedTasksWeek4.length,
            totalRepairTime: totalRepairTimeWeek1time + totalRepairTimeWeek2time + totalRepairTimeWeek3time + totalRepairTimeWeek4time,
            totalDowntime: totalDowntimeWeek1time + totalDowntimeWeek2time + totalDowntimeWeek3time + totalDowntimeWeek4time
          }
        } else if (this.getWeek === 5) {
          this.tableData.length = 0
          this.tableData.push(
            {
              kw: 1,
              tasks: tasksWeek1.length,
              // reportedTasks: reportedTasksWeek1.length,
              finishedTasks: finishedTasksWeek1.length,
              pausedTasks: pausedTasksWeek1.length,
              unclaimedTasks: unclaimedTasksWeek1.length,
              unfinishedTasks: unfinishedTasksWeek1.length,
              totalRepairTime: totalRepairTimeWeek1time,
              totalDowntime: totalDowntimeWeek1time
            },
            {
              kw: 2,
              tasks: tasksWeek2.length,
              // reportedTasks: reportedTasksWeek2.length,
              finishedTasks: finishedTasksWeek2.length,
              pausedTasks: pausedTasksWeek2.length,
              unclaimedTasks: unclaimedTasksWeek2.length,
              unfinishedTasks: unfinishedTasksWeek2.length,
              totalRepairTime: totalRepairTimeWeek2time,
              totalDowntime: totalDowntimeWeek2time
            },
            {
              kw: 3,
              tasks: tasksWeek3.length,
              // reportedTasks: reportedTasksWeek3.length,
              finishedTasks: finishedTasksWeek3.length,
              pausedTasks: pausedTasksWeek3.length,
              unclaimedTasks: unclaimedTasksWeek3.length,
              unfinishedTasks: unfinishedTasksWeek3.length,
              totalRepairTime: totalRepairTimeWeek3time,
              totalDowntime: totalDowntimeWeek3time
            },
            {
              kw: 4,
              tasks: tasksWeek4.length,
              // reportedTasks: reportedTasksWeek4.length,
              finishedTasks: finishedTasksWeek4.length,
              pausedTasks: pausedTasksWeek4.length,
              unclaimedTasks: unclaimedTasksWeek4.length,
              unfinishedTasks: unfinishedTasksWeek4.length,
              totalRepairTime: totalRepairTimeWeek4time,
              totalDowntime: totalDowntimeWeek4time
            },
            {
              kw: 5,
              tasks: tasksWeek5.length,
              // reportedTasks: reportedTasksWeek5.length,
              finishedTasks: finishedTasksWeek5.length,
              pausedTasks: pausedTasksWeek5.length,
              unclaimedTasks: unclaimedTasksWeek5.length,
              unfinishedTasks: unfinishedTasksWeek5.length,
              totalRepairTime: totalRepairTimeWeek5time,
              totalDowntime: totalDowntimeWeek5time
            }
          )
          this.summary = {
            tasks: tasksWeek1.length + tasksWeek2.length + tasksWeek3.length + tasksWeek4.length + tasksWeek5.length,
            finishedTasks: finishedTasksWeek1.length + finishedTasksWeek2.length + finishedTasksWeek3.length + finishedTasksWeek4.length + finishedTasksWeek5.length,
            pausedTasks: pausedTasksWeek1.length + pausedTasksWeek2.length + pausedTasksWeek3.length + pausedTasksWeek4.length + pausedTasksWeek5.length,
            unclaimedTasks: unclaimedTasksWeek1.length + unclaimedTasksWeek2.length + unclaimedTasksWeek3.length + unclaimedTasksWeek4.length + unclaimedTasksWeek5.length,
            unfinishedTasks: unfinishedTasksWeek1.length + unfinishedTasksWeek2.length + unfinishedTasksWeek3.length + unfinishedTasksWeek4.length + unfinishedTasksWeek5.length,
            totalRepairTime: totalRepairTimeWeek1time + totalRepairTimeWeek2time + totalRepairTimeWeek3time + totalRepairTimeWeek4time + totalRepairTimeWeek5time,
            totalDowntime: totalDowntimeWeek1time + totalDowntimeWeek2time + totalDowntimeWeek3time + totalDowntimeWeek4time + totalDowntimeWeek5time
          }
        }

        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div.machines-table {
  width: 100%;
  max-width: 1108px;
  background-color: $primary-white;
  padding: 32px;

  tr.summary {
    th {
      font-size: 14px;
    }
  }
}
</style>
